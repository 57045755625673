import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faFilter,
  faAnalytics,
  faArrowLeft,
  faArrowRight,
  faBallotCheck,
  faBars,
  faBullseyePointer,
  faChartLine,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCloudUpload,
  faEdit,
  faFolderOpen,
  faHome,
  faPaperclip,
  faPowerOff,
  faProjectDiagram,
  faRocket,
  faSignOut,
  faSlidersH,
  faTimes,
  faUser,
  faUserPlus,
  faCogs,
  faWrench,
  faLink,
  faArrowToBottom,
  faCog,
  faEnvelope,
  faBookOpen,
  faCode,
  faPause,
  faPlay,
  faUserChart,
  faCompressArrowsAlt,
  faShoppingCart,
  faMousePointer,
  faWalking,
  faPlus,
  faStar,
  faShieldCheck,
  faMobile,
  faLaptop,
  faTvAlt,
  faTablet,
  faMinus,
  faExternalLink,
  faClone,
  faTrash,
  faEye,
  faThumbsUp,
  faGlobe,
  faChartLineUp,
  faBillboard,
  faFolderHeart,
  faFolderClosed,
  faHouseBuilding,
  faCircleNotch,
} from '@fortawesome/pro-light-svg-icons';

// configure
library.add(
  faFilter,
  faAnalytics,
  faArrowLeft,
  faArrowRight,
  faBallotCheck,
  faBars,
  faArrowToBottom,
  faBullseyePointer,
  faChartLine,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCloudUpload,
  faEdit,
  faFolderOpen,
  faHome,
  faPaperclip,
  faPowerOff,
  faProjectDiagram,
  faRocket,
  faSignOut,
  faSlidersH,
  faTimes,
  faUser,
  faUserPlus,
  faCogs,
  faWrench,
  faLink,
  faCog,
  faEnvelope,
  faBookOpen,
  faCode,
  faPause,
  faPlay,
  faUserChart,
  faCompressArrowsAlt,
  faShoppingCart,
  faMousePointer,
  faWalking,
  faPlus,
  faStar,
  faShieldCheck,
  faMobile,
  faLaptop,
  faTvAlt,
  faTablet,
  faMinus,
  faExternalLink,
  faClone,
  faTrash,
  faEye,
  faThumbsUp,
  faGlobe,
  faChartLineUp,
  faBillboard,
  faFolderHeart,
  faFolderClosed,
  faHouseBuilding,
  faCircleNotch
);
