/**
 * Common store module
 *
 * Data in this module should meet the following criteria:
 *
 * - Master Data which is *not* account-specific (no account ID field)
 * - Session/user data - includes current user and account, roles, etc
 * - General UI state
 */

/* eslint-disable no-shadow */
import { make } from 'vuex-pathify';

import _ from 'underscore';

import { isBlank } from 'adready-api/helpers/common';
import { formatDateForAPI } from '@/util/apiDateFormat';
import {
  ADVANCED_PERFORMANCE_SOLUTION_ID,
  CONVERGED_TV_SOLUTION_ID,
  RANGE_LAST_30_DAYS,
  COMPARE_RANGE_PREV_DAY,
  DEFAULT_CONVERSION_WINDOW,
  KEY_DEMO_ADVERTISER_ID,
  MNI_MOTTO_SOLUTION_ID,
  PAGINATION_DEFAULT_ITEM_PER_PAGE,
  ENUM_IS_WATCH_STATS,
} from '@/constant';
import EventBus from '@/adready-vue/helpers/global/event-bus';
import { getCampaignDateRange, isDemoInstance } from '~/util/utility-functions';

const state = {
  plan: {},
  // Show filters boolean
  showFilters: false,

  // is Mni Account boolean
  isMniAccount: false,

  // is incrementality in MNI
  isIncrementalityInMni: false,
  // mni template id for top filter bar use.
  mniTemplateId: 1,

  // select one Campaign Type
  selectOneCampaignType: '',

  // is Via Account boolean
  isViaAccount: false,

  // List of all product categories
  productCategories: [],

  // List of all roles
  allRoles: [],

  // List of all users
  allUsers: [],

  // List of all organizations
  allOrganizations: [],

  // List of all applications
  allApplications: [],

  // List of all device types
  allDeviceTypes: [],

  // List of all brand safety levels
  allBrandSafetyLevels: [],

  // List of all verification partners
  allVerificationPartners: [],

  // List of all users for the current loggd in user
  allUsersForCurrentUser: [],

  // List of accounts/roles the user has, as read from their JWT
  userAccountRoles: [],

  // Map<Role Name, 1> of roles for the currently active account
  currentAccountRoles: {},

  // List of accounts user has access to, loaded from DB
  userAccounts: [],

  // List of all accounts
  accounts: [],

  // Logged-in User ID, read from JWT
  currentUserId: null,

  // Logged-in User
  currentUser: null,

  // List of user global roles.
  currentUserRoles: [],

  // whilte label domains
  whitelabelDomains: [],

  // Currently active account ID
  currentAccountId: null,
  // For Demo active advertiser name
  advName: null,

  // For Demo active advertiser name
  demoSelectedAdvertiserName: null,

  // Account wise data map store respective data as mentioned in name.
  accountMap: {},
  accountUsersMap: {},
  accountRolesMap: {},

  // Flag used to avoid race condition on watches when switching accounts.
  //
  // Since we reset the state on switch, multiple watches can fire
  // simultaneously, causing multiple data loads. We set this true before
  // switching and then reset it from a single location after the switch.
  //
  // Default to true when app starts. This is to avoid a double-load of plan
  // data on startup and will get reset to false by the orderCriteria watch in
  // PlanDashboard while the currentAccountId watch loads the data.
  switchingAccounts: true,

  // Current account object
  account: null,

  // Current selected advertiser object
  advertiser: null,

  // pixel id
  universalPixelId: null,

  // store the conversion windows data
  conversionWindowMap: {},

  // advertiser solutions
  advertiserSolutions: [],

  // filters applied count initill count
  appliedFilterCount: 0,

  leftNavLoading: true,

  // for case when user has no real advertiser assigned
  noAdvertiserAssined: false,

  ui: {
    modal: 0,
  },
  requestSentSolutions: {},
  filterCacheId: null,
  selectedMenuOption: '',
  selectedSubMenuOption: '',
  selectedElementId: '',
  isWatchStats: ENUM_IS_WATCH_STATS,
  liStatusOptions: [],
  liStatusCount: [],
  liStatusCountForPlans: [],
  plans: [],
  iosPacingData: null,
  orderCriteria: {
    sort: {
      // key/column to sort by
      col: null,
      // direction (order, e.g., asc or desc)
      dir: null,
    },
    page: {
      // page number
      num: 0,
      // records per page
      per: PAGINATION_DEFAULT_ITEM_PER_PAGE,
    },
  },
  totalPlansPages: 0,
  totalPlansCount: 0,
  plansQuery: {},
  spcGroupingData: {
    placement: {},
    publisher: {},
  },
  mediaPublisherCPMData: [],
  activeItem: undefined,
  orgLineItems: [],
  expandedPlanId: undefined,
  refreshCampaignValidation: false,
  showExportTable: false,
  selectedExportTableRow: '',
  isTransformPlanLoading: true,
  planIdForLineItemStatusCountLoading: null,
  isEventLoading: false,
  demoAdvertiserMappings: [],
};

async function fetchSolutions(ctx, advertiserId, appConsoleDataLayer) {
  const solutions = await appConsoleDataLayer.getSolutionsByAdvertiser(advertiserId);
  ctx.commit('SET_UPDATE_ADVERTISER_SOLUTIONS', solutions);
  return solutions;
}
// this function is setting  convertTV Date to back to recent days
function setConvergedTVDateFilter(ctx) {
  const { rootState } = ctx;
  const { dates } = rootState.dashboard;
  const dateRange = {
    startDate: new Date(new Date().setDate(new Date().getDate() - 31)),
    endDate: new Date(new Date().setDate(new Date().getDate() - 2)),
    compareWith: false,
    compareStartDate: new Date(new Date().setDate(new Date().getDate() - 31)),
    compareEndDate: new Date(new Date().setDate(new Date().getDate() - 3)),
    dateRangeOption: RANGE_LAST_30_DAYS,
    dateCompareOption: COMPARE_RANGE_PREV_DAY,
  };

  // dont reset dates if demo instance
  if (!isDemoInstance()) {
    ctx.commit('dashboard/SET_DATES', { ...dates, ...dateRange }, { root: true });
  }
}

async function fetchCampaigns(ctx, advertiser, account, solution, appConsoleDataLayer) {
  EventBus.isCampaignIdsReady = false;
  const advertiserId = advertiser ? advertiser.id : 0;
  const advertiserName = advertiser ? advertiser.name : '';
  const accountName = account ? account.name : '';
  const solutionId = solution ? solution.id : 0;
  const hasXandrAdvertiserId = advertiser?.xandrAdvertiserId !== null;
  const campaigns = await appConsoleDataLayer.fetchCampaignOptions(
    advertiserId,
    advertiserName,
    accountName,
    solutionId,
    hasXandrAdvertiserId
  );
  if (!campaigns.length) {
    if (!isDemoInstance()) {
      ctx.commit('dashboard/SET_RESET_DATES', {}, { root: true });
    }
    return [];
  }
  const advertiserKeys = Object.keys(ctx.getters.GET_DEMO_ADVERTISER_MAPPING_MAP).map((key) =>
    parseInt(key, 10)
  );
  const demoAdvertiserId = parseInt(localStorage.getItem(KEY_DEMO_ADVERTISER_ID), 10);
  const demoAdvertiserMapping = advertiserKeys?.includes(demoAdvertiserId)
    ? ctx.getters.GET_DEMO_ADVERTISER_MAPPING_MAP[demoAdvertiserId]
    : ctx.getters.GET_DEMO_ADVERTISER_MAPPING_MAP[1691];
  // Mark recent campaigns as default checked
  for (let i = 0; i < campaigns.length; i++) {
    if (isDemoInstance() && demoAdvertiserMapping?.selectedCampaign) {
      if (demoAdvertiserMapping?.selectedCampaign?.includes(campaigns[i].value)) {
        campaigns[i].checked = true;
      }
    } else {
      campaigns[i].checked = campaigns[i].isRecent;
    }
  }
  if (solution?.id === CONVERGED_TV_SOLUTION_ID || solution?.id === MNI_MOTTO_SOLUTION_ID) {
    setConvergedTVDateFilter(ctx);
  } else {
    // const { rootState } = ctx;
    // const { dates } = rootState.dashboard;
    // let dateRange;
    // if (isDemoInstance()) {
    //   dateRange = { ...dates };
    // } else {
    //   console.log('index');
    //   dateRange = getCampaignDateRange(campaigns, true);
    //   ctx.commit('dashboard/SET_DATES', { ...dates, ...dateRange }, { root: true });
    // }
  }
  return campaigns;
}

async function fetchAdGroups(ctx, advertiser, solution, campaignsData, appConsoleDataLayer) {
  if (solution?.id === MNI_MOTTO_SOLUTION_ID) return [];
  const advertiserId = advertiser ? advertiser.id : 0;
  const solutionId = solution ? solution.id : 0;
  const adGroupData = await appConsoleDataLayer.fetchAdGroupOptions(
    advertiserId,
    solutionId,
    campaignsData
  );
  if (adGroupData?.length > 0) {
    for (let i = 0; i < adGroupData?.length; i++) {
      adGroupData[i].checked = true;
      adGroupData[i].value = adGroupData[i].name;
    }
  }
  return adGroupData;
}

async function fetchMediaTypes(advertiserId, solutionId, payload, appConsoleDataLayer) {
  const data = await appConsoleDataLayer.fetchMediaTypeOptions(advertiserId, solutionId, payload);
  const newData = [];
  if (data.length !== 0) {
    for (let i = 0; i < data.length; i++) {
      newData.push({ value: data[i], checked: true });
    }
  }
  return newData;
}
async function fetchAudiences(advertiserId, solutionId, payload, appConsoleDataLayer) {
  let data = [];
  // if (solutionId !== MNI_MOTTO_SOLUTION_ID)
  data = await appConsoleDataLayer.fetchAudienceOptions(advertiserId, solutionId, payload);
  const uniqueData = Array.from(new Map(data.map((item) => [item.key, item])).values());
  const filterOptions = [];
  if (uniqueData.length > 0) {
    uniqueData?.forEach((d) =>
      filterOptions.push({
        key: d.value,
        value: d.key,
        checked: false,
      })
    );
  }
  return filterOptions;
}

async function fetchCreatives(advertiserId, solutionId, payload, appConsoleDataLayer) {
  const data = await appConsoleDataLayer.fetchCreativeOptions(advertiserId, solutionId, payload);
  const filterOptions = [];
  if (data.length) {
    data?.forEach((d) => filterOptions.push({ ...d, checked: false }));
  }
  return filterOptions;
}

async function fetchKeywords(advertiserId, solutionId, payload, appConsoleDataLayer) {
  if (solutionId === CONVERGED_TV_SOLUTION_ID) {
    return [];
  }
  const data = await appConsoleDataLayer.fetchKeywordOptions(advertiserId, solutionId, payload);
  const filterOptions = [];
  if (data.length) {
    data?.forEach((d) => filterOptions.push({ key: d, value: d, checked: false }));
  }
  return filterOptions;
}

async function fetchConversionPixels(advertiserId, solutionId, payload, appConsoleDataLayer) {
  if (solutionId === MNI_MOTTO_SOLUTION_ID) return [];
  if (solutionId === CONVERGED_TV_SOLUTION_ID) {
    return [];
  }
  const data = await appConsoleDataLayer.fetchConversionPixelOptions(
    advertiserId,
    solutionId,
    payload
  );
  const filterOptions = [];
  if (data.length) {
    data?.forEach((d) => filterOptions.push({ ...d, checked: true }));
  }
  return filterOptions;
}

async function fetchSolutionRelatedFilterData(
  ctx,
  advertiser,
  account,
  solution,
  appConsoleDataLayer
) {
  EventBus.campaignsReady = false;
  EventBus.mediaReady = false;
  EventBus.creativesReady = false;
  EventBus.audienceReady = false;
  const campaignsData = await fetchCampaigns(
    ctx,
    advertiser,
    account,
    solution,
    appConsoleDataLayer
  );
  const campaignIds =
    campaignsData
      .filter((e) => e.checked)
      ?.map((e) => e.key)
      ?.join(',') || '';
  const adGroupData = await fetchAdGroups(
    ctx,
    advertiser,
    solution,
    campaignsData,
    appConsoleDataLayer
  );
  const solutionId = solution ? solution.id : 0;

  const { rootState } = ctx;
  const { dates } = rootState.dashboard;
  let dateRange;
  if (isDemoInstance()) {
    dateRange = { ...dates };
  } else {
    dateRange = getCampaignDateRange(campaignsData, true);
  }

  if (dateRange.notAvailable) {
    return {
      campaignOptions: [],
      adGroupOptions: [],
      mediaTypeOptions: [],
      audienceOptions: [],
      creativeOptions: [],
      keywordOptions: [],
      pixelOptions: [],
      conversionWindow: DEFAULT_CONVERSION_WINDOW,
    };
  }

  const payload = {
    advertiser: advertiser ? advertiser.name : '',
    client: ctx.state.account ? ctx.state.account.name : '',
    campaignIds,
    adGroups: '',
    startDate: formatDateForAPI(dateRange?.startDate),
    endDate: formatDateForAPI(dateRange?.endDate),
  };

  const [
    mediaTypeData,
    audienceData,
    creativeData,
    keywordData,
    conversionPixelData,
  ] = await Promise.all([
    fetchMediaTypes(advertiser.id, solutionId, payload, appConsoleDataLayer),
    fetchAudiences(advertiser.id, solutionId, payload, appConsoleDataLayer),
    fetchCreatives(advertiser.id, solutionId, payload, appConsoleDataLayer),
    fetchKeywords(advertiser.id, solutionId, payload, appConsoleDataLayer),
    fetchConversionPixels(advertiser.id, solutionId, payload, appConsoleDataLayer),
  ]);

  return {
    campaignOptions: campaignsData || [],
    adGroupOptions: adGroupData?.length > 0 ? adGroupData : [],
    mediaTypeOptions: mediaTypeData?.length > 0 ? mediaTypeData : [],
    audienceOptions: audienceData?.length > 0 ? audienceData : [],
    creativeOptions: creativeData?.length > 0 ? creativeData : [],
    keywordOptions: keywordData?.length > 0 ? keywordData : [],
    pixelOptions: conversionPixelData?.length > 0 ? conversionPixelData : [],
  };
}

function setEmptyFilterData(ctx) {
  ctx.commit(
    'dashboard/SET_UPDATE_FILTERS_DATA',
    {
      campaignOptions: [],
      adGroupOptions: [],
      mediaTypeOptions: [],
      audienceOptions: [],
      creativeOptions: [],
      publisherOptions: [],
      keywordsOptions: [],
      pixelOptions: [],
      conversionWindow: DEFAULT_CONVERSION_WINDOW,
    },
    { root: true }
  );
}

const actions = {
  switchSolution: async (
    ctx,
    { appConsoleDataLayer, advertiserId, solutionId, isChangeFilter }
  ) => {
    const advertiser = ctx.getters.GET_ADVERTISERS_LIST.find((a) => a.id === advertiserId);
    const solution =
      solutionId === 0
        ? { id: 0 }
        : ctx.getters.GET_ADVERTISER_SOLUTIONS_LIST.find((s) => s.id === solutionId);

    if (!solution) {
      ctx.commit(
        'dashboard/SET_UPDATE_FILTERS_DATA',
        {
          campaignOptions: [],
          adGroupOptions: [],
          mediaTypeOptions: [],
          audienceOptions: [],
          creativeOptions: [],
          publisherOptions: [],
          keywordOptions: [],
          pixelOptions: [],
          conversionWindow: DEFAULT_CONVERSION_WINDOW,
        },
        { root: true }
      );
      return;
    }

    // if solution is Advanced Performance then do not fetch filter data
    if ([ADVANCED_PERFORMANCE_SOLUTION_ID].includes(solution?.id)) {
      setEmptyFilterData(ctx);
      return;
    }
    if (isChangeFilter) {
      const filterData = await fetchSolutionRelatedFilterData(
        ctx,
        advertiser,
        ctx.state.account,
        solution,
        appConsoleDataLayer
      );
      ctx.commit('dashboard/SET_UPDATE_FILTERS_DATA', filterData, { root: true });
      EventBus.isCampaignIdsReady = true;
    }
  },
  switchAdvertiser: async (ctx, { appConsoleDataLayer, advertiserId }) => {
    try {
      if (isBlank(advertiserId)) return;
      const advertiser = ctx.getters.GET_ADVERTISERS_LIST.find((a) => a.id === advertiserId);
      ctx.commit('SET_UPDATE_ADVERTISER', advertiser);
      let universalPixel;
      ctx.commit('SET_IS_WATCH_STATS', {
        IMPRESSION: true,
        REACH: true,
        CONVERSION: true,
        LIFT: true,
      });
      if (!isBlank(advertiser.xandrAdvertiserId)) {
        const pixels = await appConsoleDataLayer.flipPixels(advertiserId);
        if (pixels && _.isArray(pixels)) {
          universalPixel = pixels.find((p) => p.conversion_type === 'Universal');
        }
      }
      ctx.commit('SET_UPDATE_UNIVERSAL_PIXEL_ID', universalPixel ? universalPixel.flip_id : null);

      // set default filter data
      if (
        !ctx.state.account ||
        !advertiser ||
        isBlank(ctx.state.account.name) ||
        isBlank(advertiser.name)
      ) {
        ctx.commit(
          'dashboard/SET_UPDATE_FILTERS_DATA',
          {
            campaignOptions: [],
            adGroupOptions: [],
            mediaTypeOptions: [],
            audienceOptions: [],
            creativeOptions: [],
            publisherOptions: [],
            keywordOptions: [],
            pixelOptions: [],
            conversionWindow: DEFAULT_CONVERSION_WINDOW,
          },
          { root: true }
        );
        return;
      }

      // fetch solutions
      const solutions = await fetchSolutions(ctx, advertiserId, appConsoleDataLayer);
      const activeSolutions = solutions?.filter((s) => s.state === 'ACTIVE') || [];
      const firstSolution = activeSolutions?.length ? activeSolutions[0] : undefined;

      if (!firstSolution) {
        return;
      }

      // if solution is Advanced Performance then do not fetch filter data
      if ([ADVANCED_PERFORMANCE_SOLUTION_ID].includes(firstSolution?.id)) {
        setEmptyFilterData(ctx);
        return;
      }

      const filterData = await fetchSolutionRelatedFilterData(
        ctx,
        advertiser,
        ctx.state.account,
        firstSolution,
        appConsoleDataLayer
      );

      ctx.commit('dashboard/SET_UPDATE_FILTERS_DATA', filterData, { root: true });
      EventBus.isCampaignIdsReady = true;
    } catch (err) {
      console.error('error switching advertiser ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
      EventBus.isCampaignIdsReady = true;
      throw err;
    }
  },
  setRefreshCampaignValidation: ({ commit }, payload) => {
    commit('SET_REFRESH_CAMPAIGN_VALIDATION', payload);
  },
  updateActiveItem: ({ commit }, payload) => {
    commit('SET_ACTIVE_ITEM', payload);
  },
  setNewCampaignLoader: ({ commit }, payload) => {
    commit('SET_NEW_CAMPAIGNS_LOADER', payload);
  },
  setShowFilters: ({ commit }, payload) => {
    commit('SET_SHOW_FILTERS', payload);
  },
  setAppliedFilterCount: ({ commit }, payload) => {
    commit('SET_APPLIED_FILTER_COUNT', payload);
  },
  setFilterCacheId: ({ commit }, payload) => {
    commit('SET_FILTER_CACHE_ID', payload);
  },
  setConversionWindow: ({ commit }, payload) => {
    commit('SET_CONVERSION_WINDOW', payload);
  },
  setIsMniAccount: ({ commit }, payload) => {
    commit('SET_IS_MNI_ACCOUNT', payload);
  },
  setIsIncrementalityInMni: ({ commit }, payload) => {
    commit('SET_IS_INCREMENTALITY_IN_MNI', payload);
  },
  setMniTemplateId: ({ commit }, payload) => {
    commit('SET_MNI_TEMPLATE_ID', payload);
  },
  setIsViaAccount: ({ commit }, payload) => {
    commit('SET_IS_VIA_ACCOUNT', payload);
  },
  setSelectedMenuOptions: ({ commit }, payload) => {
    if (_.isObject(payload)) {
      commit('SET_SELECTED_SUB_MENU_OPTION', payload.subMenu);
      commit('SET_SELECTED_MENU_OPTION', payload.menu);
      commit('SET_SELECTED_ELEMENT_ID', payload.elementId);
      return;
    }
    commit('SET_SELECTED_SUB_MENU_OPTION', '');
    commit('SET_SELECTED_ELEMENT_ID', '');
    commit('SET_SELECTED_MENU_OPTION', payload);
  },
  setIsWatchStats: ({ commit }, payload) => {
    commit('SET_IS_WATCH_STATS', payload);
  },
  setSpcGroupingData: ({ commit }, payload) => {
    commit('SET_SPC_GROUPING_DATA', payload);
  },
  setSelectOneCampaignType: ({ commit }, payload) => {
    commit('SET_SELECT_ONE_CAMPAIGN_TYPE', payload);
  },
  setPlans: ({ commit }, payload) => {
    commit('SET_PLANS', payload);
  },
  setTransformPlanLoading: ({ commit }, payload) => {
    commit('SET_TRANSFORM_PLAN_LOADING', payload);
  },
  addPlan: ({ commit }, plan) => {
    commit('ADD_PLAN', plan);
  },
  updatePlans: ({ commit }, payload) => {
    commit('UPDATE_PLANS', payload);
  },
  deletePlan: ({ commit }, plan) => {
    commit('DELETE_PLAN', plan);
  },
  addLineItem: ({ commit }, li) => {
    commit('ADD_LINE_ITEM', li);
  },
  deleteLineItem: ({ commit }, li) => {
    commit('DELETE_LINE_ITEM', li);
  },
  updateLineItem: ({ commit }, payload) => {
    commit('UPDATE_LINE_ITEM', payload);
  },
  updateOrgLineItem: ({ commit }, payload) => {
    commit('UPDATE_ORG_LINE_ITEM', payload);
  },
  updatePlan: ({ commit }, payload) => {
    commit('UPDATE_PLAN', payload);
  },
  updateSelectedAdvertiser: ({ commit }, payload) => {
    commit('SET_UPDATE_ADVERTISER', payload);
  },
  toggleShowExportTable: ({ commit }, payload) => {
    commit('TOGGLE_SHOW_EXPORT_TABLE', payload);
  },
  setSelectedExportTableRow: ({ commit }, payload) => {
    commit('SET_SELECTED_EXPORT_TABLE_ROW', payload);
  },
  setEventLoading: ({ commit }, payload) => {
    commit('SET_EVENT_LOADING', payload);
  },
};

const mutations = {
  ...make.mutations(state),
  SET_TRANSFORM_PLAN_LOADING: (state, payload) => {
    state.isTransformPlanLoading = payload;
  },
  SET_ACTIVE_ITEM: (state, payload) => {
    state.activeItem = payload;
  },
  SET_SHOW_FILTERS: (state, payload) => {
    state.showFilters = payload;
  },
  SET_APPLIED_FILTER_COUNT: (state, payload) => {
    state.appliedFilterCount = payload;
  },
  SET_FILTER_CACHE_ID: (state, payload) => {
    state.filterCacheId = payload;
  },
  SET_UPDATE_UNIVERSAL_PIXEL_ID: (state, id) => {
    state.universalPixelId = id;
  },
  SET_IS_MNI_ACCOUNT: (state, payload) => {
    state.isMniAccount = payload;
  },
  SET_IS_INCREMENTALITY_IN_MNI: (state, payload) => {
    state.isIncrementalityInMni = payload;
  },
  SET_MNI_TEMPLATE_ID: (state, payload) => {
    state.mniTemplateId = payload;
  },
  SET_IS_VIA_ACCOUNT: (state, payload) => {
    state.isViaAccount = payload;
  },
  SET_UPDATE_ADVERTISER_SOLUTIONS: (state, solutions) => {
    state.advertiserSolutions = solutions || [];
  },
  SET_UPDATE_ADVERTISER: (state, advertiser) => {
    state.advertiser = advertiser;
  },
  SET_RESET_CONVERSION_WINDOW: (state, conversionWindow) => {
    state.conversionWindow = conversionWindow;
  },
  SET_SELECTED_SUB_MENU_OPTION: (state, payload) => {
    state.selectedSubMenuOption = payload;
  },
  SET_SELECTED_MENU_OPTION: (state, payload) => {
    state.selectedMenuOption = payload;
  },
  SET_SELECTED_ELEMENT_ID: (state, payload) => {
    state.selectedElementId = payload;
  },
  SET_IS_WATCH_STATS: (state, payload) => {
    state.isWatchStats = payload;
  },
  SET_SELECT_ONE_CAMPAIGN_OPTION: (state, payload) => {
    state.showFilters = payload;
  },
  SET_CONVERSION_WINDOW: (state, payload) => {
    state.conversionWindowMap[payload.windowType] = payload;
  },
  SET_SPC_GROUPING_DATA: (state, payload) => {
    state.spcGroupingData = payload;
  },
  SET_NEW_CAMPAIGNS_LOADER: (state, payload) => {
    state.newCampaignLoader = payload;
  },
  SET_REFRESH_CAMPAIGN_VALIDATION: (state, payload) => {
    state.refreshCampaignValidation = payload;
  },
  SET_PLANS: (state, payload) => {
    // always replace existing plans with the new ones
    state.totalPlansPages = payload.totalPages;
    state.totalPlansCount = payload.totalElements;
    state.plans = [];
    state.plansMap = {};
    payload.content.forEach((plan) => {
      state.plansMap[plan.id] = plan;
      state.plansMap[plan._uuid] = plan;
      state.plans.push(plan);
    });
  },
  ADD_LINE_ITEM: (state, li) => {
    state.plan.lineItems.push(li);
  },
  ADD_PLAN: (state, plan) => {
    state.plans.unshift(plan);
  },
  SET_EVENT_LOADING(state, payload) {
    state.isEventLoading = payload;
  },

  UPDATE_PLANS: (state, payload) => {
    const { plan } = state;
    if (!plan) {
      return;
    }
    Object.assign(plan, { ...payload.plan, updatedPlan: true });
  },
  DELETE_PLAN: (state, plan) => {
    const planIdx = state.plans.findIndex((p) => p._uuid === plan._uuid);
    if (planIdx < 0) {
      console.warn("warning: couldn't find plan for uuid=", plan._uuid);
      return;
    }
    state.plans.splice(planIdx, 1);
  },
  DELETE_LINE_ITEM: (state, li) => {
    const lineItemIdx = state.plan.lineItems.findIndex((l) => l._uuid === li._uuid);
    if (lineItemIdx < 0) {
      console.warn("warning: couldn't find plan for uuid=", li._uuid);
      return;
    }
    state.plan.lineItems.splice(lineItemIdx, 1);
  },

  UPDATE_LINE_ITEM: (state, payload) => {
    const lineItem = state.plan.lineItems.find((l) => l._uuid === payload._uuid);
    if (!lineItem) {
      console.warn("couldn't find line item for uuid=", payload._uuid);
      return;
    }
    Object.assign(lineItem, payload.lineItem);
  },
  UPDATE_ORG_LINE_ITEM: (state, payload) => {
    const lineItem = state.orgLineItems.find((l) => l.id === payload.lineItem.id);
    if (!lineItem) {
      console.warn("couldn't find line item for id=", payload.lineItem.id);
      return;
    }
    Object.assign(lineItem, payload.lineItem);
  },

  UPDATE_PLAN: (state, payload) => {
    let plan = state.plans.find((p) => p.id === payload.plan.id);
    if (!plan) {
      plan = state.plans.find((p) => p._uuid === payload.plan._uuid);
      if (!plan) {
        console.warn("couldn't find plan for id=", payload.plan.id);
        return;
      }
    }
    Object.assign(plan, payload.plan);
  },
  TOGGLE_SHOW_EXPORT_TABLE: (state, payload) => {
    state.showExportTable = payload;
  },
  SET_SELECTED_EXPORT_TABLE_ROW: (state, payload) => {
    state.selectedExportTableRow = payload;
  },
  SET_DEMO_ADVERTISER_MAPPING: (state, demoAdvertiserMappings) => {
    state.demoAdvertiserMappings = demoAdvertiserMappings || [];
  },
};

const getters = {
  getAccount: (state) => (id) => {
    return state.accountMap[id];
  },
  getAccountRoles: (state) => (id) => {
    return state.accountRolesMap[id];
  },
  getConversionWindow: (state) => (type) => {
    return state.conversionWindowMap[type];
  },
  GET_ADVERTISERS_LIST(st) {
    if (!st.account) {
      return [];
    }
    return _.sortBy(st.account.advertisers, (a) => a.name.toLowerCase());
  },
  GET_ADVERTISER_SOLUTIONS_LIST(state) {
    return state.advertiserSolutions;
  },
  GET_DEMO_ADVERTISER_MAPPING_LIST(state) {
    return state.demoAdvertiserMappings;
  },
  GET_DEMO_ADVERTISER_MAPPING_MAP(state) {
    return state.demoAdvertiserMappings.reduce((acc, item) => {
      acc[item.advertiserId] = {
        accountId: item.accountId,
        advertiserId: item.advertiserId,
        startDate: item.startDate,
        endDate: item.endDate,
        compareStartDate: item.compareStartDate,
        compareEndDate: item.compareEndDate,
        solutionId: item.solutionId,
        selectedCampaign: item.selectedCampaigns,
      };
      return acc;
    }, {});
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
