import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faCalendarAlt,
  faCalendar,
  faCaretDown,
  faCaretUp,
  faChartPie,
  faCheck,
  faCheckCircle,
  faTimesCircle,
  faCircleDashed,
  faCircle,
  faMinusCircle,
  faCheckSquare,
  faClone,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faClipboard,
  faClipboardCheck,
  faCommentAltExclamation,
  faEdit,
  faEllipsisH,
  faEye,
  faInfoCircle,
  faPlus,
  faRedoAlt,
  faRocket,
  faSearch,
  faSignOut,
  faSort,
  faSquare,
  faTimes,
  faToggleOff,
  faToggleOn,
  faUser,
  faUserPlus,
  faCogs,
  faWrench,
  faClock,
  faLink,
  faVideo,
  faPlayCircle,
  faUsers,
  faTvRetro,
  faBookOpen,
  faInfoSquare,
  faCode,
  faChartLine,
  faPause,
  faPhotoVideo,
  faAd,
  faCloudUploadAlt,
  faPlay,
  faBullseyeArrow,
  faStar,
  faShieldCheck,
  faTachometerAltSlow,
  faTachometerAltAverage,
  faTachometerAltFast,
  faChartPieAlt,
  faChartBar,
  faWallet,
  faFileInvoice,
  faCreditCardFront,
  faExternalLink,
  faTrash,
  faEnvelopeOpen,
  faChartNetwork,
  faThumbsUp,
  faGlobe,
  faHouseChimney,
  faFolderMagnifyingGlass,
  faFolderClosed,
  faFolderHeart,
  faPenRuler,
  faChartLineUp,
  faBillboard,
  faChartWaterfall,
  faArrowUpRightAndArrowDownLeftFromCenter,
  faArrowDownLeftAndArrowUpRightToCenter,
  faFilter,
  faChartMixed,
  faTvAlt,
  faFolderOpen,
  faHouseBuilding,
  faCircleNotch,
} from '@fortawesome/pro-regular-svg-icons';

// configure
library.add(
  faFilter,
  faChevronDown,
  faCaretDown,
  faCaretUp,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
  faCalendar,
  faChartPie,
  faCheck,
  faCheckCircle,
  faTimesCircle,
  faCircleDashed,
  faCircle,
  faMinusCircle,
  faCheckSquare,
  faClone,
  faClipboard,
  faClipboardCheck,
  faCommentAltExclamation,
  faEdit,
  faEllipsisH,
  faEye,
  faInfoCircle,
  faPlus,
  faRedoAlt,
  faRocket,
  faSearch,
  faSignOut,
  faSort,
  faSquare,
  faTimes,
  faToggleOff,
  faToggleOn,
  faUser,
  faUserPlus,
  faCogs,
  faWrench,
  faClock,
  faEdit,
  faLink,
  faVideo,
  faPlayCircle,
  faUsers,
  faTvRetro,
  faBookOpen,
  faInfoSquare,
  faCode,
  faChartLine,
  faPause,
  faPhotoVideo,
  faAd,
  faCloudUploadAlt,
  faPlay,
  faBullseyeArrow,
  faStar,
  faShieldCheck,
  faTachometerAltSlow,
  faTachometerAltAverage,
  faTachometerAltFast,
  faChartPieAlt,
  faChartBar,
  faWallet,
  faFileInvoice,
  faCreditCardFront,
  faExternalLink,
  faTrash,
  faEnvelopeOpen,
  faChartNetwork,
  faThumbsUp,
  faGlobe,
  faHouseChimney,
  faFolderMagnifyingGlass,
  faFolderClosed,
  faFolderHeart,
  faPenRuler,
  faChartLineUp,
  faBillboard,
  faChartWaterfall,
  faArrowUpRightAndArrowDownLeftFromCenter,
  faArrowDownLeftAndArrowUpRightToCenter,
  faChartMixed,
  faTvAlt,
  faFolderOpen,
  faHouseBuilding,
  faCircleNotch
);
